@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand';
  overflow-x: hidden;
}
body *{
  font-family:  'Quicksand';
}
.f700{
  font-weight: 700;
}
.f600{
  font-weight: 600;
}
.f500{
  font-weight: 500;
}
.f400{
  font-weight: 400;
}
.f300{
  font-weight: 300;
}
.yellow{
  color: #A97A13;
}
.bgyellow{
  background-color: #F7BC3C;
}
.yellow2{
  color: #DB8642;
}