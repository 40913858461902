.panel {
    padding: 96px 1vw;
}

.panel-nav {
    display: flex;
    width: 100%;
    justify-content: space-around;
    border-bottom: 1px #A9A9A9 solid;
}

.panel-nav p {
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
}

.panel h1 {
    text-align: center;
}

.input-flex {
    display: flex;
}

.input-lang-group {
    width: 30%;
    margin: 0 auto;
    background-color: #e7e7e7;
    padding: 0 5%;
    border-radius: 24px;

    box-shadow: 0px 4px 21px -12px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 0px 4px 21px -12px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 4px 21px -12px rgba(0, 0, 0, 1);
}

.input-lang-group h3 {
    font-size: 32px;
    color: #5e5e5e;
}

.input-lang-group h4 {
    font-size: 20px;
    font-weight: 500;
    color: #5e5e5e;
}

.input-group-list {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
}

.input-group-list input {
    border-radius: 8px;
    border: none;
    padding: 8px;
    margin: 4px 0;
}

.panel .localeeditor .save {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.panel .localeeditor button {
    text-align: center;
    padding: 16px;
    margin: 32px auto;
    font-size: 24px;
    background-color: #e7e7e7;
    border: none;
    border-radius: 24px;
    width: 60%;
}

.panel .localeeditor button:active {
    background-color: #b9b9b9;

}

.contact-forms {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    padding: 16px 0;

}

.contact-form {
    width: 30%;
    
    margin: 8px 0;
    background-color: #A9A9A9;
    border-radius: 16px;
    padding: 16px;
    font-size: 24px;
    color: #FFFFFF;
}

.contact-form p span {
    background: #5e5e5e;
    padding: 0 8px;
    border-radius: 8px;
}

.new-edit {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.new-edit input,
.new-edit textarea {
    margin: 16px 0;
    border-radius: 8px;
    border: none;
    padding: 8px;
    background-color: #e2e2e2;
}

.new-edit button {
    background-color: yellow;
    padding: 8px;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    font-size: 24px;
}

.blog-list {
    text-align: center;
}

.blog-section .blog-list-container {
    padding: 16px 5vw;
    width: 85%;
    max-width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 64px;
}

.blog-list .blog-list-container .blog-post {
    width: 100%;
    background-color: #f3f3f3;
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 16px;
}

.blog-list .blog-list-container .blog-post img {
    width: 100%;
}

.blog-post-footer-author{
    display: flex;
}
.blog-post-footer-author .blog-post-footer-author-img{
    width: 96px;
    height: 96px;
    margin-right: 5%;
    border-radius: 100%;
    overflow: hidden;
}
.blog-post .footer-edit{
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
}
.blog-post .footer-edit button{
    padding: 8px;
    margin: 0 8px;
    font-size: 24px;
    border-radius: 24px;
    border: none;
    color: #FFFFFF;
    background-color: #A9A9A9;
    cursor: pointer;
}
.blog-post .footer-edit button:nth-child(1) {
    background-color: #002fcb;
}
.blog-post .footer-edit button:nth-child(2) {
    background-color: #cb1800;
}
.edit-blog{
    position: fixed;
    top: 96px;
    left: calc(50% - 432px);
    width: 800px;
    background: #b9b9b9;
    padding: 160px 32px;
    border-radius: 32px;
    overflow: scroll;
    height: 60vh;
    overflow-x: hidden;
}
.edit-blog i{
    cursor: pointer;
}
.edit-blog textarea{
    height: auto !important;
    padding-bottom: 128px;
}
.hidden{
    display: none;
}