.login-page {
    padding: 0px 10vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 128px;
}

.login-input {
    margin: auto;
    width: 256px;
    height: 216px;
    background-color: #a9a9a9;
    padding: 16px;
    border-radius: 16px;
    text-align: center;
}
.login-input input{
    margin: 16px 0;
    padding: 8px;
    border: none;
    border-radius: 16px;
    font-size: 20px;
}
.login-input button{
    width: 100%;
    padding: 8px;
    font-size: 20px;
    border: none;
    background-color: gray;
    border-radius: 16px;
    cursor: pointer;

}
.error-box{
    background-color: red;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 700;
    margin: 8px 0;
}